.reload-page { }

.reload-page.fetching .supersymetric { opacity: 0;  }
.reload-page.loaded .outbox { margin-top: 10vh; }
.reload-page .outbox { opacity: 1; padding: 0; width: calc( 760vh * var( --font-convert-ratio ) ); }

.reload-page .box { padding: 3vh 2vh; width: calc( 760vh * var( --font-convert-ratio ) );  display: flex; align-items: center; border-bottom: 0.3vh solid #fff; }
.reload-page .box .box-left { display: block;  width: calc( 540vh * var( --font-convert-ratio ) ); text-align: left; }
.reload-page .box .box-right { display: block; width: calc( 220vh * var( --font-convert-ratio ) ); text-align: right; }

.reload-page h3 { color: #fff; font-size: calc( 28vh * var( --font-convert-ratio ) ); font-height: calc( 40vh * var( --font-convert-ratio ) );
    margin-bottom: calc( 10vh * var( --font-convert-ratio ) ); font-weight: normal; }     

.reload-page p { color: #fff; font-size: calc( 20vh * var( --font-convert-ratio ) ); font-height: calc( 26vh * var( --font-convert-ratio ) );
    margin-bottom: 0; font-style: italic; opacity: 0.5; }
         
.reload-page button { border-radius: calc( 18vh * var( --font-convert-ratio ) ); font-size: calc( 14vh * var( --font-convert-ratio ) ); text-align: center; display: inline-block; cursor: pointer !important;
    line-height: calc( 36vh * var( --font-convert-ratio ) ); text-align: center; padding: 0 calc( 14vh * var( --font-convert-ratio ) ); color: #fff; background: #FF8A22; font-weight: bold;
    height: calc( 36vh * var( --font-convert-ratio ) );  border-radius: calc( 22vh * var( --font-convert-ratio ) ); font-size: calc( 18vh * var( --font-convert-ratio ) ); 
    line-height: calc( 46vh * var( --font-convert-ratio ) ); padding: 0 calc( 14vh * var( --font-convert-ratio ) ); height: calc( 46vh * var( --font-convert-ratio ) ); }
    
.reload-page .tty { background: rgba(0,0,0,0.5); color: #fff; padding: 1.5vh; border-radius: 1.4vh; margin-top: 4vh; font-family: monospace; text-shadow: 2px 2px #000; 
    font-size: calc( 13vh * var( --font-convert-ratio ) ); line-height: calc( 26vh * var( --font-convert-ratio ) ); }

.reload-page .tty > div { padding: 0; height: calc( 26vh * var( --font-convert-ratio ) ); white-space: nowrap !important; overflow: hidden; }
    
.reload-page .tty .key { color: #eeee00; }
.reload-page .tty .string { color: #e8ecf1; }
.reload-page .tty .number { color: #81cfe0; }
.reload-page .tty .boolean { color: #f1a9a0; }