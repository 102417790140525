.App { height: 100vh; width: 100vw; overflow: hidden; margin: 0 auto; }

.App.regular-mode { }

.App.regular-mode .main-content,
.App.regular-mode .search-content { }

.App.regular-mode .main-content { opacity: 1; pointer-events: all; height: auto; }
.App.regular-mode .search-content { opacity: 0; pointer-events: none; height: 0; position: relative; top: 0; }

.App.search-mode { background: #2a2a2a; }

.App.search-mode .main-content { opacity: 0; pointer-events: none; height: 0; }
.App.search-mode .search-content { opacity: 1; pointer-events: all; position: absolute; top: 8vh; width: 100%; }


.App.ws-error { background: #2a2a2a; }
.App.ws-error .vertical-flex { opacity: 0.2; pointer-events: none; }
.App.ws-error .vertical-flex * { pointer-events: none; }

.error { background: #fff; width: 50%; z-index: 99999; padding: 4vh; font-size: calc( 20vh * var( --font-convert-ratio ) ); line-height: calc( 24vh * var( --font-convert-ratio ) );  }
.error h3 { margin-bottom:  calc( 10vh * var( --font-convert-ratio ) ); }
.error p { margin-bottom: calc( 15vh * var( --font-convert-ratio ) ); }
.error p + p { position: relative; margin-bottom: calc( 10vh * var( --font-convert-ratio ) ); top: calc( -10vh * var( --font-convert-ratio ) ); opacity: 0.6; }